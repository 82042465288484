import React, { useCallback, useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, useTheme } from '@mui/material';

import { FormElementCheckboxProps } from '../FormTypes';

const FormElementCheckbox = ({ name, label }: FormElementCheckboxProps) => {
  const { control, setValue } = useFormContext();
  const theme = useTheme();

  useEffect(() => {
    setValue(name, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render: ControllerProps['render'] = useCallback(({ field }) => {
    const value = field.value || false;

    return label ? (
      <FormControlLabel
        control={<Checkbox checked={value} onChange={(event) => field.onChange(event.target.checked)} />}
        label={label}
        sx={{ color: theme.palette.text.primary }}
      />
    ) : (
      <Checkbox checked={value} onChange={(event) => field.onChange(event.target.checked)} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Controller control={control} name={name} render={render} />;
};

export default FormElementCheckbox;
