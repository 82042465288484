import React, { useEffect, useId, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import withStaticModal, { StaticModalWrappedComponent } from '../withStaticModal';
import {
  checkBoxSchema, looseOptional, nonEmptyStringsArray, stringWithCommonLimitSchema,
} from '../../resources/formSchemas';
import { BaseModal } from '../BaseModal';
import { FormModalNotificationsValues, ModalNotificationsProps } from './NotificationsModalTypes';
import { INotificationBody } from '../../lib/types';
import { useAppDispatch } from '../../hooks';
import { createNotification, updateNotification } from '../../redux/thunkActions/notificationsActions';
import { validateCronPattern } from '../../lib/utils/validateCronPattern';
import Form from '../../form/Form/Form';
import FormElement from '../../form/FormElement/FormElement';
import {getCompanyUsers} from '../../redux/thunkActions/dataActions';
import {SelectItem} from '../../form/FormTypes';

const NotificationsModal: StaticModalWrappedComponent<ModalNotificationsProps> = ({
  isOpen,
  onClose,
  data: modalData,
}) => {
  const formId = useId();
  const dispatch = useAppDispatch();
  const [users, setUsers] = useState<SelectItem[]>([])
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  useEffect(() => {
    if (modalData?.notification?.secondMessage) {
      setShowSecondMessage(true);
    }
  }, [modalData]);

  const formSchema = z.object({
    [FormModalNotificationsValues.DISABLED]: checkBoxSchema,
    [FormModalNotificationsValues.NAME]: stringWithCommonLimitSchema,
    [FormModalNotificationsValues.CHAT_ID]: stringWithCommonLimitSchema,
    [FormModalNotificationsValues.THREAD_ID]: looseOptional(stringWithCommonLimitSchema),
    [FormModalNotificationsValues.MENTIONS]: nonEmptyStringsArray,
    [FormModalNotificationsValues.CRON_PATTERN]: stringWithCommonLimitSchema.refine(
      (data) => validateCronPattern(data),
      'Неверный формат',
    ),
    [FormModalNotificationsValues.TEXT]: stringWithCommonLimitSchema,
    [FormModalNotificationsValues.SECOND_MESSAGE]: looseOptional(stringWithCommonLimitSchema),
    [FormModalNotificationsValues.SECOND_MESSAGE_DELAY]: looseOptional(z.coerce.number()),
  });

  type FormData = z.infer<typeof formSchema>;
  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: useMemo(
      () => ({
        disabled: modalData?.notification?.disabled,
        name: modalData?.notification?.name,
        mentions: modalData?.notification?.mentions?.map(i => i.id),
        text: modalData?.notification?.text,
        chatId: modalData?.notification?.chatId,
        threadId: modalData?.notification?.threadId,
        cronPattern: modalData?.notification?.cronPattern,
        secondMessage: modalData?.notification?.secondMessage,
        delay: modalData?.notification?.delay,
      }),
      [modalData],
    ),
  });

  const onSubmit = (data: FormData) => {
    const preparedData: INotificationBody = data;

    if (modalData?.notification?.id) {
      dispatch(updateNotification(modalData.notification.id, preparedData));
      onClose();
    } else {
      dispatch(createNotification(preparedData));
      onClose();
    }
  };

  useEffect(() => {
    dispatch(getCompanyUsers()).then(data => setUsers(data.map(i => ({
      value: i.id,
      label: `${i.firstName} ${i.lastName}`
    }))));

  }, [dispatch]);
  useEffect(() => {
    if (!showSecondMessage) {
      methods.resetField('secondMessage');
      methods.resetField('delay');
    }
  }, [showSecondMessage, methods]);

  const modalActionsElements = () => (
    <>
      <Button form={formId} type='submit'>
        Сохранить
      </Button>
      <Button variant='contained' onClick={onClose}>
        Отмена
      </Button>
    </>
  );

  return (
    <BaseModal
      title='Редактировать уведомление'
      isOpen={isOpen}
      onClose={onClose}
      modalActions={modalActionsElements()}
    >
      <Form contextMethods={methods} id={formId} onSubmit={onSubmit}>
        <FormElement
          {...methods.register(FormModalNotificationsValues.DISABLED)}
          component='checkbox'
          label='Приостановить отправку уведомлений'
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.NAME)}
          component='input'
          label='Название'
          margin='normal'
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.CHAT_ID)}
          component='input'
          label='ID Чата'
          margin='normal'
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.THREAD_ID)}
          component='input'
          label='ID Топика'
          margin='normal'
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.CRON_PATTERN)}
          component='input'
          label='Крон паттерн'
          placeholder='*/2 * * * *'
          margin='normal'
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.MENTIONS)}
          component='select'
          label='Кого упомянуть в сообщении'
          isMultiple={true}
          renderSelected={'placeholder'}
          values={users}
        />
        <FormElement
          {...methods.register(FormModalNotificationsValues.TEXT)}
          component='input'
          label='Текст'
          margin='normal'
        />

        <FormControlLabel
          control={<Checkbox checked={showSecondMessage} onChange={() => setShowSecondMessage((prev) => !prev)} />}
          label='Отправить повторное сообщение'
        />

        {showSecondMessage && (
          <>
            <FormElement
              {...methods.register(FormModalNotificationsValues.SECOND_MESSAGE)}
              component='input'
              label='Повторное сообщение'
              margin='normal'
            />
            <FormElement
              {...methods.register(FormModalNotificationsValues.SECOND_MESSAGE_DELAY)}
              component='input'
              label='Задержка повторного сообщения'
              margin='normal'
            />
          </>
        )}
      </Form>
    </BaseModal>
  );
};

export default withStaticModal<ModalNotificationsProps>(NotificationsModal);
